import type { GraphQLError } from 'graphql';

const QUERY_FETCH_MOT_DUE_DATE = `query GetMotDueDate($vrm: String!) {
    getMotDueDate(vrm: $vrm) {
      vrm
      status
      expiryDate
      make
    }
  }`;

export interface MotDueDate {
  email: string;
  vrm: string;
  status: string;
  expiryDate?: string;
  make?: string;
}

export const fetchMotDueDate = async (vrm: string, email: string, graphApi: string): Promise<MotDueDate> => {
  const res = await fetch(graphApi, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      query: QUERY_FETCH_MOT_DUE_DATE,
      variables: {
        // clean spaces as the API expects a VRM without spaces
        vrm: vrm.replace(/\s+/g, ""), 
      },
    }),
  });

  if (!res.ok) {
    throw new Error(`getMotDueDate request failed: ${res.status} ${res.statusText}`);
  }

  const gqlRes: { data: { getMotDueDate: MotDueDate }; errors?: GraphQLError[] } = await res.json();

  if (gqlRes.errors?.length) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal -- GQL requests can bring partial data alongside errors
    throw gqlRes;
  }

  return { ...gqlRes.data?.getMotDueDate, email };
};
