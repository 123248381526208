'use client';

import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { Img } from '@/components/atoms/Img';

import styles from './index.module.scss';

/**
 * @todo move back to SC after AB tests
 */
export function WhyFixterTag({
  hide,
  children,
}: {
  readonly hide: boolean;
  readonly children: ReactNode;
}): ReactNode {
  const hideForAbTest = [
    useActiveExperiment(ActiveExperiments.HomepageServicesComponentFR, 'B'),
    useActiveExperiment(ActiveExperiments.HomepageHeroNavServicesUK, 'B'),
  ].some(Boolean);

  return hideForAbTest ? (
    <div className={classNames(styles.whyFixterTag, styles.hideForAbTest)} />
  ) : (
    <div className={classNames(styles.whyFixterTag, hide && styles.hideTag)}>
      <div className={styles.logo}>
        <Img provider="cloudinary" src="/logo/oan79s6rhkhruphm9e8k.svg" alt="Fixter" width={17} height={20} />
      </div>
      <div className={styles.whyFixter}>{children}</div>
    </div>
  );
}
