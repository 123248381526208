'use client';

import classNames from 'classnames';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { Img } from '@/components/atoms/Img';

import styles from './index.module.scss';

/**
 * @todo move back to SC after AB tests
 */
export function YellowCarImage(): JSX.Element {
  const upToLargeMask = [
    useActiveExperiment(ActiveExperiments.HomepageServicesComponentFR, 'B'),
    useActiveExperiment(ActiveExperiments.HomepageHeroNavServicesUK, 'B'),
  ].some(Boolean);

  return (
    <div className={classNames(styles.image, upToLargeMask && styles.upToLargeMask)}>
      <Img
        className={styles.yellowCar}
        provider="cloudinary"
        src="/brand/zyalv06adtgymg37qvph.webp"
        alt="yellow-car"
        width={334}
        height={334}
      />
    </div>
  );
}
