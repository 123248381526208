import type { GraphQLError } from 'graphql';

export const MUTATION_CREATE_MOT_REMINDER_LEAD = `
    mutation createMotReminderLeadRequest($leadInput: LeadInputGql!) {
      createLeadRequest(leadInput: $leadInput) {
        email
      }
    }
  `;

interface CreateLeadResponse {
  email: string;
}
interface CreateLeadRequest {
  readonly email: string;
  readonly payload: {
    motDueDate?: string;
    make?: string;
  };
  readonly skus: string[];
  readonly type: string;
  readonly vrm: string;
}
export const createMotReminderLead = async (
  leadInput: CreateLeadRequest,
  graphApi: string
): Promise<CreateLeadResponse> => {
  const res = await fetch(graphApi, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      query: MUTATION_CREATE_MOT_REMINDER_LEAD,
      variables: {
        leadInput,
      },
    }),
  });

  if (!res.ok) {
    throw new Error(`createMotReminderLead request failed: ${res.status} ${res.statusText}`);
  }

  const gqlRes: { data: { createLeadRequest: CreateLeadResponse }; errors?: GraphQLError[] } =
    await res.json();

  if (gqlRes.errors?.length) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal -- GQL requests can bring partial data alongside errors
    throw gqlRes;
  }

  return gqlRes.data?.createLeadRequest;
};
