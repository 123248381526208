'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { translations as vrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import { Button, DesignType } from '@/components/atoms/Button';
import { VrmLookupDumb } from '../VrmLookupDumb';
import { createMotReminderLead } from './util/createLeadRequest';
import type { MotReminderModalProps } from './util';
import {
  getModalCurrentState,
  getIconType,
  getIconStyle,
  getHeading,
  getInfo,
  ModalCurrentStateEnum,
} from './util';

import styles from './index.module.scss';

export function MotReminderModal({
  className,
  motData,
  locale,
  campaignId,
  graphApi,
  children,
  onClose,
}: MotReminderModalProps): JSX.Element {
  const { vrm, email, status, expiryDate, make } = motData;
  const modalCurrentState = useMemo(() => getModalCurrentState(status, expiryDate), [expiryDate, status]);
  const [icon, setIcon] = useState(getIconType(modalCurrentState));
  const [iconStyle, setIconStyle] = useState(getIconStyle(modalCurrentState));
  const [heading, setHeading] = useState(getHeading(modalCurrentState, vrm, expiryDate));
  const [info, setInfo] = useState(getInfo(modalCurrentState));
  const [loading, setLoading] = useState(false);
  const [reminderSet, setReminderSet] = useState(false);
  const [vrmLookupOn, setVrmLookupOn] = useState(false);
  const isExpiredOrExpiresSoon =
    modalCurrentState === ModalCurrentStateEnum.EXPIRES_SOON ||
    modalCurrentState === ModalCurrentStateEnum.MOT_EXPIRED;
  const expiresSoon = modalCurrentState === ModalCurrentStateEnum.EXPIRES_SOON;
  const isExpired = modalCurrentState === ModalCurrentStateEnum.MOT_EXPIRED;

  useEffect(() => {
    if (!reminderSet) {
      setHeading(getHeading(modalCurrentState, vrm, expiryDate));
      setIcon(getIconType(modalCurrentState));
      setIconStyle(getIconStyle(modalCurrentState));
      setInfo(getInfo(modalCurrentState));
    } else if (modalCurrentState === ModalCurrentStateEnum.DEFAULT) {
      setHeading(<>All set! You&apos;ll never forget your MOT again</>);
      setInfo('You will receive a reminder by email before your MOT is due');
      setIcon('communication/mail-01');
    } else setInfo("All set! You'll never forget your MOT again");
  }, [modalCurrentState, vrm, expiryDate, reminderSet]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      setLoading(true);
      const leadInput = {
        email,
        payload: {
          motDueDate: expiryDate,
          make,
        },
        skus: ['mot'],
        type: 'motReminder',
        vrm: vrm.replace(/\s+/g, ''),
      };
      try {
        await createMotReminderLead(leadInput, graphApi);
        setReminderSet(true);
      } catch (error) {
        console.error('Error creating MOT reminder lead:', error);
      }

      setLoading(false);
    },
    [email, expiryDate, make, vrm, graphApi]
  );
  return (
    <div className={className}>
      <div className={styles.component}>
        {!vrmLookupOn && (
          <div className={classNames(styles.statusIcon, iconStyle)}>
            <Icon
              className={classNames(iconStyle, styles.icon)}
              id={icon}
              width={32}
              height={32}
              legacy={false}
              data-testid="mot-reminder-icon"
            />
          </div>
        )}
        <HeadingLevels semanticLevel={6} className={styles.heading}>
          {!vrmLookupOn ? (
            heading
          ) : (
            <>
              Fill in your details
              <br />
              so we can find a garage
              <br />
              near you
            </>
          )}
        </HeadingLevels>
        {!vrmLookupOn && isExpiredOrExpiresSoon && (
          <div className={classNames(styles.vrmLookup, !isExpired && styles.borderBottom)}>
            <Button
              designType={DesignType.PRIMARY}
              className={styles.vrmLookupCta}
              size="M"
              type="submit"
              fullWidth
              onClick={() => setVrmLookupOn(true)}
            >
              <span>{vrmLookupFormTranslations[locale].instantQuote.textCTA}</span>
            </Button>
          </div>
        )}
        {vrmLookupOn && (
          <VrmLookupDumb
            className={styles.vrmLookupForm}
            locale={locale}
            sku="mot"
            {...vrmLookupFormTranslations[locale].default}
            textCTA="Next"
            campaignId={campaignId}
            initialVrm={vrm}
            hideVrm
            ctaProps={{ size: 'M' }}
          />
        )}
        {!vrmLookupOn && (
          <>
            {!isExpired && (
              <p className={classNames(styles.info, expiresSoon && reminderSet && styles.expiresInfo)}>
                {reminderSet && expiresSoon && (
                  <Icon id="general/check-circle" width={24} height={24} legacy={false} />
                )}
                {info}
              </p>
            )}
            {reminderSet
              ? !expiresSoon && (
                  <Button
                    designType={DesignType.CTA_DARK}
                    className={styles.confirmationButton}
                    size="M"
                    type="submit"
                    fullWidth
                    noIcon
                    onClick={onClose}
                  >
                    Got it
                  </Button>
                )
              : !isExpired && (
                  <form onSubmit={handleSubmit} className={styles.form}>
                    <Button
                      designType={expiresSoon ? DesignType.CTA_DARK : DesignType.PRIMARY}
                      className={expiresSoon ? styles.cta : ''}
                      size="M"
                      type="submit"
                      fullWidth
                      iconId="feedback/bell-03"
                      disabled={modalCurrentState === ModalCurrentStateEnum.NOT_FOUND}
                      loading={loading}
                    >
                      Set a reminder
                    </Button>
                  </form>
                )}
          </>
        )}
        {children}
      </div>
    </div>
  );
}
MotReminderModal.displayName = 'MotReminderModal';
