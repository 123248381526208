/* eslint-disable max-classes-per-file */
import { Locale } from '@fxtr/i18n';
import { vrmUKRegex, vrmFRRegex } from '$constants/regexp';

export const sanitizeString = (str: string): string => String(str).replace(/\s+/g, '').trim();

type VrmValidationErrorCode = 'EMPTY' | 'INVALID';

export class VrmValidationError extends Error {
  static EMPTY = 'EMPTY' as const;

  static INVALID = 'INVALID' as const;

  code: VrmValidationErrorCode;

  constructor(code: VrmValidationErrorCode) {
    super();
    this.name = this.constructor.name;
    this.code = code;
  }
}

export const validateVrm = (vrm: string, locale: Locale): void => {
  if (!vrm.length) {
    throw new VrmValidationError(VrmValidationError.EMPTY);
  }

  switch (locale) {
    case Locale.FR_FR:
      if (!vrmFRRegex.test(vrm)) {
        throw new VrmValidationError(VrmValidationError.INVALID);
      }
      break;
    default:
      if (vrm.length > 8 || !vrmUKRegex.test(vrm)) {
        throw new VrmValidationError(VrmValidationError.INVALID);
      }
      break;
  }
};

type EmailValidationErrorCode = 'EMPTY' | 'INVALID';

export class EmailValidationError extends Error {
  static EMPTY = 'EMPTY' as const;

  static INVALID = 'INVALID' as const;

  code: EmailValidationErrorCode;

  constructor(code: EmailValidationErrorCode) {
    super();
    this.name = this.constructor.name;
    this.code = code;
  }
}

export const validateEmail = (email: string): void => {
  if (!email.length) {
    throw new EmailValidationError(EmailValidationError.EMPTY);
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    throw new EmailValidationError(EmailValidationError.INVALID);
  }
};
