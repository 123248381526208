'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import type { Locale } from '@fxtr/i18n';
import { useModalQueue } from '$util/hooks/useModalQueue';
import { TrustPilot } from '@/components/atoms/TrustPilot';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { Icon } from '@/components/atoms/Icon';
import { Button, DesignType } from '@/components/atoms/Button';
import { Row, Col } from '@/components/atoms/Grid';
import { Img } from '@/components/atoms/Img';
import { ContentComponentSection } from '@/components/atoms/ContentComponentSection';
import { MotReminderModal } from '../MotReminderModal';
import { validateVrm, VrmValidationError, validateEmail, EmailValidationError } from './utils/validation';
import { fetchMotDueDate } from './utils/fetchMotDueDate';

import styles from './index.module.scss';

export interface MotReminderProps extends ComponentPropsWithoutRef<'div'> {
  readonly locale: Locale;
  readonly graphApi: string;
  readonly heading: React.ReactNode;
  readonly yourRegistration: string;
  readonly yourEmail: string;
  readonly cta: string;
  readonly invalidRegistration: string;
  readonly missingRegistration: string;
  readonly invalidEmail: string;
  readonly missingEmail: string;
  readonly campaignId?: string;
}

export function MotReminder({ className, children, ...props }: MotReminderProps): JSX.Element {
  const {
    id,
    locale,
    graphApi,
    heading,
    yourRegistration,
    yourEmail,
    cta,
    invalidRegistration,
    invalidEmail,
    missingEmail,
    missingRegistration,
    campaignId,
  } = props;
  const [errors, setErrors] = useState({ vrm: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({ vrm: '', email: '' });

  const validateFormData = useCallback(
    (formData: typeof formState) => {
      const { vrm, email } = formData;

      let vrmError = '';
      let emailError = '';

      try {
        validateVrm(vrm, locale);
      } catch (err) {
        if (err instanceof VrmValidationError) {
          switch (err.code) {
            case VrmValidationError.EMPTY:
              vrmError = missingRegistration;
              break;
            case VrmValidationError.INVALID:
              vrmError = invalidRegistration;
              break;
            default:
              break;
          }
        }
      }

      try {
        validateEmail(email);
      } catch (err) {
        if (err instanceof EmailValidationError) {
          switch (err.code) {
            case EmailValidationError.EMPTY:
              emailError = missingEmail;
              break;
            case EmailValidationError.INVALID:
              emailError = invalidEmail;
              break;
            default:
              break;
          }
        }
      }

      setErrors({ vrm: vrmError, email: emailError });
      return !vrmError && !emailError;
    },
    [locale, missingRegistration, invalidRegistration, missingEmail, invalidEmail]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const key = name as keyof typeof formState;
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      if (errors[key]) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }
    },
    [errors]
  );

  const [, queueModal, unqueueModal] = useModalQueue();

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const isValid = validateFormData(formState);
      if (!isValid) return;
      const { vrm, email } = formState;
      setLoading(true);

      try {
        const motFetchData = await fetchMotDueDate(vrm, email, graphApi);
        queueModal([
          <MotReminderModal
            key="mot-reminder"
            motData={motFetchData}
            locale={locale}
            campaignId={campaignId}
            onClose={() => {
              unqueueModal();
              setFormState({ vrm: '', email: '' });
            }}
            graphApi={graphApi}
            className={styles.motReminderModal}
          />,
          'small-modal',
        ]);
      } catch (error) {
        console.error('Error fetching MOT due date:', error);
      }

      setLoading(false);
    },
    [campaignId, formState, graphApi, locale, queueModal, unqueueModal, validateFormData]
  );

  return (
    <ContentComponentSection
      id={id}
      className={classNames(MotReminder.displayName, styles.component, className)}
      {...props}
    >
      <Row className={styles.row}>
        <Col className={classNames(styles.container)}>
          <div className={classNames(styles.heading)}>
            <div className={styles.bell}>
              <Icon id="feedback/bell-ringing-04" width={40} height={40} legacy={false} />
            </div>
            <HeadingLevels semanticLevel={3} styleLevel={3} className={styles.headingText}>
              {heading}
            </HeadingLevels>
          </div>
          <div className={styles.trustpilot}>
            <TrustPilot locale={locale} width="100%" theme="dark" />
          </div>
          <div className={styles.form}>
            <form onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  name="vrm"
                  value={formState.vrm}
                  onChange={handleChange}
                  className={classNames(styles.input, errors.vrm && styles.inputError)}
                  placeholder={yourRegistration}
                  data-testid="mot-reminder-input-vrm"
                />
                <Icon className={styles.icon} id="travel/car-01" width={24} height={24} legacy={false} />
                {errors.vrm && <div className={styles.errorMessage}>{errors.vrm}</div>}
              </div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  className={classNames(styles.input, errors.email && styles.inputError)}
                  placeholder={yourEmail}
                  data-testid="mot-reminder-input-email"
                />
                <Icon
                  className={styles.icon}
                  id="communication/mail-01"
                  width={24}
                  height={24}
                  legacy={false}
                />
                {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
              </div>
              <Button
                designType={DesignType.CTA_DARK}
                className={styles.cta}
                size="L"
                type="submit"
                fullWidth
                noIcon={!loading}
                loading={loading}
              >
                {cta}
              </Button>
            </form>
          </div>
          <Img
            src="/lines/vgalvnwzqoiu5dchz3i5"
            provider="cloudinary"
            alt="Mot reminder"
            className={styles.tracks}
            width="841"
            height="581"
          />
        </Col>
      </Row>
    </ContentComponentSection>
  );
}
MotReminder.displayName = 'MotReminder';
