import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { Locale } from '@fxtr/i18n';
import dayjs from 'dayjs';
import { MotStatusTypes } from '$declarations/mostStatus';
import type { MotDueDate } from '../../MotReminder/utils/fetchMotDueDate';
import styles from '../index.module.scss';

export const DAYS_EXPIRES_SOON = 45;
export interface MotReminderModalProps extends ComponentPropsWithoutRef<'div'> {
  readonly motData: MotDueDate;
  readonly locale: Locale;
  readonly campaignId?: string;
  readonly onClose: () => void;
  readonly graphApi: string;
}

export enum ModalCurrentStateEnum {
  DEFAULT = 'default',
  EXPIRES_SOON = 'expiresSoon',
  MOT_EXPIRED = 'motExpired',
  NOT_FOUND = 'notFound',
}

export const getIconType = (modalCurrentState: ModalCurrentStateEnum): string => {
  if (modalCurrentState === ModalCurrentStateEnum.DEFAULT) return 'feedback/bell-03';
  return 'feedback/alert-circle';
};

export const getIconStyle = (modalCurrentState: ModalCurrentStateEnum): string => {
  if (modalCurrentState === ModalCurrentStateEnum.DEFAULT) return styles.purpleIcon;
  return styles.redIcon;
};

export const getInfo = (modalCurrentState: ModalCurrentStateEnum): string =>
  modalCurrentState === ModalCurrentStateEnum.NOT_FOUND
    ? 'Please check that you entered your VRM correctly.'
    : 'Would you like to receive a reminder by email before your MOT is due?';

export const getHeading = (
  modalCurrentState: ModalCurrentStateEnum,
  vrm: string,
  expiryDate?: string
): ReactNode => {
  if (modalCurrentState === ModalCurrentStateEnum.DEFAULT)
    return <>Your MOT is due on {dayjs(expiryDate).format('DD/MM/YYYY')}</>;

  if (modalCurrentState === ModalCurrentStateEnum.EXPIRES_SOON)
    return (
      <>
        Your MOT is due in <span>{DAYS_EXPIRES_SOON} days</span>
      </>
    );
  if (modalCurrentState === ModalCurrentStateEnum.MOT_EXPIRED)
    return (
      <>
        Your MOT is <b>expired</b>
      </>
    );
  return <span>We couldn&apos;t find an MOT due date for {vrm} </span>;
};

export const getModalCurrentState = (status: string, expiryDate?: string): ModalCurrentStateEnum => {
  if (status === MotStatusTypes.PENDING || !expiryDate) return ModalCurrentStateEnum.NOT_FOUND;

  const today = new Date();
  const expiryDateObj = new Date(expiryDate);
  if (expiryDateObj < today) return ModalCurrentStateEnum.MOT_EXPIRED;

  const futureDate = new Date();
  futureDate.setDate(today.getDate() + DAYS_EXPIRES_SOON);
  if (expiryDateObj < futureDate) return ModalCurrentStateEnum.EXPIRES_SOON;

  return ModalCurrentStateEnum.DEFAULT;
};
